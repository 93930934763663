@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/fonts" as *;

.header {
  &__logo-container {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }

  &__logo {
    padding: 1rem;
  }

  &__nav {
  }

  &__select {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    flex-direction: column;
    @include desktop {
      padding-right: 2.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 0.15rem;
    }
}

    &__home {
      text-decoration: none;
      font-family: "Finlandica";
      font-weight: 100;
      text-transform: uppercase;
      font-size: 1.2rem;
      @include desktop {
        font-size: 1.5rem;
      
      }
    }

    &__home:hover {
      color: #fff;
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
        0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
    }

    &__about {
      text-decoration: none;
      font-family: "Finlandica";
      font-weight: 100;
      text-transform: uppercase;
      font-size: 1.2rem;
      @include desktop {
        font-size: 1.5rem;
      
      }
    }

    &__about:hover {
      color: #fff;
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
        0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
    }
  

  &__contact {
    text-decoration: none;
      font-family: "Finlandica";
      font-weight: 100;
      text-transform: uppercase;
      font-size: 1.2rem;
      @include desktop {
        font-size: 1.5rem;
      
      }
  }

  &__contact:hover {
    color: #fff;
    text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
      0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
  }

  &__link {
    color: $text;
  }
}
