@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/fonts" as *;

.about {
  color: white;
  margin-bottom: 2rem;
  @include desktop {
    margin-bottom: 4rem;
  }

  &__container {
    padding: 1.5rem;
  }

  &__header-container {
    margin: 4rem;

    
  }

  &__intro-container {
    display: flex;
    align-items: center;
  
    flex-direction: column;
    @include desktop {
      margin-left: 7rem;
      flex-direction: row;
    }
  }

  &__avatar {
    border-radius: 50%;
   width: 9em;
   margin-bottom: 2rem;
    @include desktop {
      width: 12rem;
      padding-top: 3rem;
    }
  }

  &__intro-header {
    font-size: 3rem;
    text-transform: capitalize;
    margin-bottom: 2rem;
    @include desktop {
      margin-left: 5rem;
      margin-top: 6rem;
    }
    
  }

  &__paragraph {
    color: $text;
    margin: 1rem;
   
    @include desktop {
      margin: 0rem 10rem 2rem 21rem;
    }
  }

  &__stack-title {
    color: $text;
    font-weight: 600;
    margin: 1rem;
    @include desktop {
      margin: 3rem 10rem 0rem 21rem;
    }
  }

  &__stack {
    color: $text;
    margin: 1rem;
    @include desktop {
      margin: 3rem 10rem 0rem 21rem;
    }
  }

}
