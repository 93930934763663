@use "../../partials/variables" as *;
@use "../../partials/mixins" as *;

.instock {
  color: white;
  margin-bottom: 3rem;

  &__container {
    display: flex;
    flex-direction: column;
    align-content: center;
    @include desktop {
      margin: 0rem 0rem 1rem 0rem;
      // margin: 2rem 5rem;
    }
  }

  &__back-arrow {
    margin: 2rem;
    height: 2rem;
  }

  &__container {
  }

  &__title {
    margin-left: 2rem;
    @include desktop {
      margin-left: 6rem;
    }
  }

  &__demo {
    @include desktop {
      height: 40rem;
      margin: 2rem 0rem;
    }
  }

  &__demo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem 2rem;
  }

  &__demo-video {
    width: 100%;
    height: 10rem;
    margin: 1rem;
    @include desktop {
      height: 40rem;
    }
  }

  &__link {
    color: white;
    text-decoration: none;
    font-size: 0.6rem;
    @include desktop {
      font-size: 1rem;
    }
  }

  &__link:hover {
    text-decoration: underline white 1px solid;
  }

  &__description-title {
    font-weight: 600;
    margin: 2rem 2rem 1rem 2rem;

    @include desktop {
      
      margin: 1rem 5rem;
    }
  }

  &__description {
    margin: 0rem 2rem;
    font-size: 0.9rem;
    @include desktop {
      margin-bottom: 2rem;
      margin: 1rem 5rem;
      padding-bottom: 2rem;
    
      font-size: 1rem;
    }
  }

  &__install {
    font-weight: 600;
    margin: 2rem 2rem 1rem 2rem;
    @include desktop {
      margin: 1rem 5rem;
    }
  }

  &__install-description {
    margin: 0rem 2rem;
    font-size: 0.9rem;
    @include desktop {
      margin-bottom: 2rem;
      margin: 2rem 5rem;
      font-size: 1rem;
    }
  }

  &__github-link {
    color: white;
    margin: 2rem 2rem;
    font-size: 0.9rem;
    font-weight: 600;
    @include desktop {
      margin-bottom: 2rem;
      margin: .5rem 5rem;
      font-size: 1rem;
    }
  }
}
