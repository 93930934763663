@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/fonts" as *;

* {
  margin: 0;
  padding: 0;
}

.home {
  color: $text;
  margin: 0;
  margin: 7rem 2rem 2rem 4rem;
  // margin-left: 4rem;

  &__container {
  }

  &__date {
    font-family: "Finlandica";
    font-size: 1.5rem;
    padding-bottom: 2rem;
    @include desktop {
      font-size: 2rem;
    }
  }

  &__portfolio {
    font-family: "Finlandica";
    padding-bottom: 5rem;
    text-transform: uppercase;
    font-size: 2rem;

    @include desktop {
      font-size: 4rem;
    }
  }

  &__name {
    font-weight: 300;
    font-family: monospace;
    color: $text;
    margin-bottom: 7rem;
    font-size: 1rem;
    @include desktop {
      margin: 5rem 4rem 3rem 3rem;
      font-size: 1.5rem;
    }
  }

  &__description {
    color: $text;
    font-family: monospace;
    margin: 2rem 7rem 3rem 3rem;
  }

  &__button {
    color: white;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }

  &__header-container {
    display: flex;
    flex-direction: column;

    @include desktop {
      position: absolute;
      bottom: 3rem;
    }
  }
}
