@use "../../partials/mixins" as *;
@use "../../partials/variables" as *;
@use "../../partials/fonts" as *;

.contact {
  &__info-container {
    background-color: $background;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    @include desktop {
        font-size: 2rem;
        margin-top: 10rem;
      }
    
    
    // @include tablet {
    //   position: relative;
    //   left: 5rem;
      
    // }
    // @include desktop {
    //   position: relative;
    //   left: 30rem;
    //   top: 10rem;
    // }
  }

  &__header-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__heading {
    color: $text;
  }

  &__name {
    color: $text;
    font-family: monospace;
    // position: relative;
    // left: 8rem;
    // @include desktop {
    //   font-size: 3rem;
    //   margin-bottom: 2rem;
    //   position: relative; 
    //   left: 0rem;
    // }
  }

  &__email {
    // position: relative;
    // left: 5.2em;
    color: $text;
    font-family: monospace;

    // @include desktop {
    //   position: relative;
    //   left: 4rem;
      
    // }
  }

  &__linkedin-container {
    color: $text;
    display: flex;
    justify-content: center;

    // @include tablet {
    //   position: relative;
    //   left: 14rem;
      
    // }
    // @include desktop {
    //   position: relative;
    //   left: 34rem;
      
    // }
  }

  &__linkedin-social {
    height: 2rem;
    margin: 1rem;
    // position: relative;
    // left: 14.5rem;
    // top: 1rem;
  }

  &__github-social {
    height: 3rem;
    margin: 0rem 2rem 0rem 1rem;
    // position: relative;
    // left: 15rem;
    bottom: 0.4rem;
  }

  &__linked {
    // position: relative;
    // left: 14rem;
    font-family: monospace;
    margin: 1rem;
  }

  &__linked:hover {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  &__github-container {
    color: $text;
    display: flex;
    justify-content: center;
    margin-right: 2rem;

    // @include tablet {
    //   position: relative;
    //   left: 14rem;
      
    // }

    // @include desktop {
    //   position: relative;
    //   left: 34rem;
      
    // }
  }

  &__github {
    // position: relative;
    // left: 15rem;
    font-family: monospace;
    margin-top: .5rem;
  }

  &__github:hover {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  &__linkedin-link {
    display: flex;
    color: white;
  }

  &__github-link {
    display: flex;
    color: white;
  }
}
