@use "./variables" as *;
@use './mixins' as *;
@import url('https://fonts.googleapis.com/css2?family=Finlandica:wght@400;500&display=swap');

// Labels, Buttons, Links


h1 {
    // Mobile
    font-family: 'Finlandica';
    font-size: 1rem; // 28px
    line-height: 2.25rem; // 36px
    font-weight: 400; // Reg
  }

p {
    // Mobile
    font-family: 'Finlandica';
    font-size: 1rem; // 28px
    line-height: 2.25rem; // 36px
    font-weight: 200; // Reg
  }